




















































































































































































import { Component } from "vue-property-decorator";
import { AccessToken, OktaAuth } from "@okta/okta-auth-js";
import { AxiosResponse } from "axios";
import config from "@/okta-config";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import InputLabel from "@/components/forms/InputLabel.vue";
import UserStatusIndicator from "@/components/user-management/UserStatusIndicator.vue";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { userManagementService } from "@/services/user-management.service";
import { customerService } from "@/services/customer.service";
import { paymentCustomerService } from "@/services/payments/payment-customer.service";
import Constants from "@/shared/constants/user-management";
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";
import AppRoles from "@/shared/constants/application-roles";
import { DEPROVISIONED } from "@/shared/constants/user-lifecycle-statuses";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";
import { DataTableHeader } from "@/models/vuetify/data-table-header";
import { RavenUserManagementAddRequest } from "@/models/external-services/raven-api-user-management-add-request";
import { RavenUserManagementUpdateRequest } from "@/models/external-services/raven-api-user-management-update-request";
import { ApplicationUser } from "@/interfaces/application-user";

const authJs = new OktaAuth({
  issuer: (config.oidc.issuer as string).split("oauth2")[0],
  clientId: config.oidc.clientId,
  redirectUri: config.oidc.redirectUri,
  pkce: true,
  tokenManager: {
    autoRenew: true
  }
});

@Component({
  components: {
    "primary-button": PrimaryButton,
    "icon-button": IconButton,
    "simple-confirmation-modal": SimpleConfirmationModal,
    "input-label": InputLabel,
    "icon-tooltip": IconTooltip,
    "user-status-indicator": UserStatusIndicator
  }
})
export default class UserConfiguration extends UserRolesMixin {
  // reactive class properties
  private isLoadingUsers = false;
  private isConfirmationModalDisplayed = false;
  private confirmationModalText = ""; // text displayed in body of confirmation modal
  private confirmationModalTitle = ""; // title displayed on confirmation modal
  private confirmationModalCancelButtonText = "";
  private confirmationModalConfirmButtonText = "";
  private userActionSelected = ""; // "Delete", "Deactivate", "Reset MFA", or "Reset Password"
  private userSelectedForAction: ApplicationUser | null = null;

  private isUserModalDisplayed = false;
  private isUserFormValidated = true; // change this default to false when adding API calls
  private isDuplicateUserValidated = true; // change this default to false when adding API calls
  private isSavingUserInProcess = false; // determines whether to deactivate save button during update/save requests
  private duplicateFound: any | null = null;
  private userModalTitle = "";
  private userModalSubtitle = "";

  private readonly defaultUser = {
    firstName: "",
    lastName: "",
    email: "",
    role: [],
    documentGroups: [],
    userID: "" // this is the DB userId, not Okta userId
  };
  private cloneUser = Object.assign({}, this.defaultUser);
  private isUserNewOrExisting = Constants.NEW_USER;

  private users: ApplicationUser[] = [];
  private userTableHeaders: DataTableHeader[] = [
    {
      text: "Name",
      value: "userName",
      align: "start",
      sortable: true
    },
    {
      text: "Email",
      value: "email",
      align: "start",
      sortable: true
    },
    {
      text: "Document Groups",
      value: "documentGroups",
      align: "start",
      sortable: false
    },
    {
      text: "Role",
      value: "roles",
      align: "start",
      sortable: false
    },
    {
      text: "Status",
      value: "status",
      align: "start",
      sortable: false
    },
    {
      text: "Actions",
      value: "actions",
      align: "start",
      sortable: false
    }
  ];
  private readonly paymentsCustomerRoleOptions = [AppRoles.PAYMENTS_SPECIALIST];
  private readonly apCustomerRoleOptions = [
    AppRoles.FINANCIAL_ACCOUNTING,
    AppRoles.AP_SPECIALIST,
    AppRoles.AP_MANAGER,
    AppRoles.AP_ADMIN,
    AppRoles.USER_ADMIN
  ];
  private documentGroupOptions: string[] = [];
  private nameRules = [
    (value: string) => !!value || "First and Last names are required"
  ];
  private emailRules = [
    (value: string) => !!value || "E-mail is required",
    (value: string) => /.+@.+/.test(value) || "E-mail must be valid"
  ];

  private shouldShowDeactivateUserButton: boolean = this.$launchDarkly.variation(
    "show-deactivate-user-button",
    false
  );
  private shouldShowResetPasswordButton: boolean = this.$launchDarkly.variation(
    "show-reset-user-password-button",
    false
  );
  private shouldShowResetFactorsButton: boolean = this.$launchDarkly.variation(
    "show-reset-user-mfa-button",
    false
  );

  // computed properties
  private get o4oToken(): string {
    return this.userStore.getO4oToken;
  }

  private get isPaymentsCustomer(): boolean {
    return this.userStore.getIsPaymentsCustomer;
  }

  private get customerRoleOptions(): string[] {
    if (this.isPaymentsCustomer) {
      return [
        ...this.apCustomerRoleOptions,
        ...this.paymentsCustomerRoleOptions
      ];
    } else {
      return this.apCustomerRoleOptions;
    }
  }

  private get internalRoleOptions(): string[] {
    return [
      ...this.customerRoleOptions,
      AppRoles.VS_MANAGER,
      AppRoles.VS_SPECIALIST,
      AppRoles.ASCEND_ADMIN
    ];
  }

  private get roleOptions(): string[] {
    // return internal role options in internal environments
    return !this.isCustomerFacingEnv &&
      (this.userStore.getSelectedCustomer?.id ?? 0) === 10012
      ? this.internalRoleOptions
      : this.customerRoleOptions;
  }

  // lifecycle methods

  async created(): Promise<void> {
    this.isLoadingUsers = true;

    await this.initO4oToken();

    // If user is not Ascend, store the current customer in application state
    // If user is Ascend, a customer will already be selected/stored
    if (!this.isCustomerFacingEnv) {
      // customerid set to 10012 on this page for non-prod environments
      // to match test ascend group in Okta for non-prod testing
      this.userStore.setSelectedCustomer({
        id: 10012,
        description: "ascend",
        displayValue: "ascend",
        region: process.env.VUE_APP_REGION
      });
    } else if (!this.isAscendUser) {
      const customerRegion = await customerService.getCustomerRegion();
      const customerName = userManagementService.getCustomerShortname(
        this.user.groups
      );
      this.userStore.setSelectedCustomer({
        id: this.userStore.getUser.customerid,
        description: customerName,
        displayValue: customerName,
        region: customerRegion
      });
    }

    if (this.shouldShowPayments) {
      await this.checkPaymentsCustomerStatus();
    }

    await this.populateUserTable();
    this.isLoadingUsers = false;
  }

  // methods
  async initO4oToken() {
    // silently request new token with scopes to manage users and groups
    const scopesResponse = await authJs.token.getWithoutPrompt({
      scopes: [
        "openid",
        "okta.users.read",
        "okta.users.manage",
        "okta.groups.read",
        "okta.groups.manage"
      ]
    });
    if (scopesResponse?.tokens?.accessToken) {
      const adminAccessToken = scopesResponse.tokens.accessToken as AccessToken;

      // store o4oToken for okta-user-management in vuex store
      this.userStore.setO4oToken(adminAccessToken.accessToken);
    } else {
      // no o4o token available, redirect to 403 page
      checkCurrentRouteAndRedirect(this.$router, "/403/access");
    }
  }

  async populateUserTable() {
    const tableData = await userManagementService.loadUserTableDataAsync();
    this.users = tableData.users;
    this.documentGroupOptions = tableData.documentGroups;
  }

  async checkPaymentsCustomerStatus() {
    const customerStatus = await paymentCustomerService.getStatus();
    this.userStore.setIsPaymentsCustomer(customerStatus?.is_active ?? false);
  }

  resetValidations(): void {
    const form = this.$refs.userForm as any;
    form.resetValidation(); // clears Vuetify validation errors
  }

  openConfirmationModal(modalType: string, user: ApplicationUser) {
    // store which action is selected
    this.userActionSelected = modalType;
    this.userSelectedForAction = user;

    // determine what to display in modal body
    switch (modalType) {
      case "Delete":
        this.confirmationModalTitle = "Delete User";
        this.confirmationModalText =
          "Are you sure you want to permanently delete this user? This action can’t be undone.";
        this.confirmationModalCancelButtonText = "No, Keep User";
        this.confirmationModalConfirmButtonText = "Yes, Delete User";
        break;
      case "Deactivate":
        this.confirmationModalTitle = "Deactivate User";
        this.confirmationModalText =
          "Are you sure you want to deactivate this user? They won’t be able to access AscendAP until you reactivate their account.";
        this.confirmationModalCancelButtonText = "No, Keep User";
        this.confirmationModalConfirmButtonText = "Yes, Deactivate User";
        break;
      case "Reset Password":
        this.confirmationModalTitle = "Reset Password";
        this.confirmationModalText =
          "Are you sure you want to reset this user's password? If your organization uses SSO, the user's password should be reset with your SSO provider instead.";
        this.confirmationModalCancelButtonText = "Cancel";
        this.confirmationModalConfirmButtonText = "Yes, Reset Password";
        break;
      case "Reset MFA":
        this.confirmationModalTitle = "Reset MFA";
        this.confirmationModalText =
          "Are you sure you want to reset all multifactor authentication factors configured for this user?";
        this.confirmationModalCancelButtonText = "Cancel";
        this.confirmationModalConfirmButtonText = "Yes, Reset MFA";
        break;
      default:
        break;
    }

    // open Delete or Deactivate modal
    this.isConfirmationModalDisplayed = true;
  }

  closeConfirmationModal() {
    this.isConfirmationModalDisplayed = false;
    this.confirmationModalText = "";
    this.userActionSelected = "";
  }

  resetFormValidations() {
    // clear Vuetify validation error messages
    const form = this.$refs.userForm as any;
    form.resetValidation();
  }

  openNewUserModal() {
    this.isUserNewOrExisting = Constants.NEW_USER;
    this.userModalTitle = "Add a New User";
    this.userModalSubtitle = "";

    // provide blank user object to form
    this.cloneUser = Object.assign({}, this.defaultUser);
    this.isUserModalDisplayed = true;
    this.$nextTick(() => this.resetFormValidations());
  }

  editExistingUser(user: any) {
    this.isUserNewOrExisting = Constants.EXISTING_USER;
    this.userModalTitle = "Edit an Existing User";
    this.userModalSubtitle = "Edit the user's information below";
    this.cloneUser = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.roles,
      documentGroups: user.documentGroups,
      userID: user.userID
    };
    this.isUserModalDisplayed = true;
    this.$nextTick(() => this.resetFormValidations());
  }

  @Catch((error: any, context: any) => {
    context.$toasted.show(
      "<p>Error occurred, please try again later.</p>",
      options.ERROR_OPTIONS
    );
  })
  async handleUserActionConfirmation() {
    switch (this.userActionSelected) {
      case "Delete":
        await this.deleteUserAsync();
        break;
      case "Deactivate":
        await this.deactivateUserAsync();
        break;
      case "Reset Password":
        await this.resetUserPasswordAsync();
        break;
      case "Reset MFA":
        await this.resetUserMultifactorAuthenticationAsync();
        break;
    }
    this.closeConfirmationModal();
    this.populateUserTable(); // reload user table data
  }

  displayResponseToast(response: AxiosResponse<any>, toastMessage: string) {
    if (
      response.status !== 200 ||
      (response.data.statusCode && response.data.statusCode !== 200)
    ) {
      this.$toasted.show(
        "<p>Error occurred, please try again later.</p>",
        options.ERROR_OPTIONS
      );
    } else {
      this.$toasted.show(toastMessage, options.SUCCESS_OPTIONS);
    }
  }

  async deleteUserAsync() {
    if (this.userSelectedForAction) {
      const response = await userManagementService.deleteUserAsync(
        this.userSelectedForAction.userID
      );
      this.displayResponseToast(response, "<p>User has been deleted.</p>");
    }
  }

  async deactivateUserAsync() {
    if (this.userSelectedForAction) {
      const response = await userManagementService.deactivateUserAsync(
        this.userSelectedForAction.email
      );
      this.displayResponseToast(response, "<p>User has been deactivated.</p>");
    }
  }

  async resetUserPasswordAsync() {
    if (this.userSelectedForAction) {
      const response = await userManagementService.resetUserPasswordAsync(
        this.userSelectedForAction.oktaId
      );
      this.displayResponseToast(
        response,
        "<p>User password successfully reset.</p>"
      );
    }
  }

  async resetUserMultifactorAuthenticationAsync() {
    if (this.userSelectedForAction) {
      const response = await userManagementService.resetUserFactorsAsync(
        this.userSelectedForAction.oktaId
      );
      this.displayResponseToast(
        response,
        "<p>User authentication factors successfully reset.</p>"
      );
    }
  }

  closeUserModalWithoutSaving() {
    this.isUserModalDisplayed = false;
    // reset cloneUser to defaultUser
    this.cloneUser = Object.assign({}, this.defaultUser);
  }

  @Catch((error: any, context: any) => {
    context.$toasted.show(
      "<p>There was a problem adding the user profile. Please try again later.</p>",
      options.ERROR_OPTIONS
    );
  })
  async saveUser() {
    this.isSavingUserInProcess = true; // deactivate save button in modal temporarily

    if (this.isUserNewOrExisting === Constants.NEW_USER) {
      const request: RavenUserManagementAddRequest = new RavenUserManagementAddRequest(
        this.cloneUser.email,
        this.cloneUser.firstName,
        this.cloneUser.lastName,
        this.cloneUser.role,
        this.cloneUser.documentGroups
      );
      const response = await userManagementService.createNewUserAsync(request);

      this.displayResponseToast(
        response,
        "<p>New user has been added. They will receive an email to gain access to your organization.</p>"
      );
    } else if (this.isUserNewOrExisting === Constants.EXISTING_USER) {
      const request: RavenUserManagementUpdateRequest = new RavenUserManagementUpdateRequest(
        this.cloneUser.userID,
        this.cloneUser.firstName,
        this.cloneUser.lastName,
        this.cloneUser.role,
        this.cloneUser.documentGroups
      );
      const response = await userManagementService.updateUserAsync(request);

      this.displayResponseToast(
        response,
        "<p>Existing user profile has been updated.</p>"
      );
    }
    this.isSavingUserInProcess = false;
    this.isUserModalDisplayed = false;
    // reset cloneUser
    this.cloneUser = Object.assign({}, this.defaultUser);
    await this.populateUserTable(); // load new data for table
  }

  isUserAbleToBeDeactivated(user: ApplicationUser): boolean {
    return user.status != DEPROVISIONED;
  }
}
