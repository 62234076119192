






import { Vue, Component, Prop } from "vue-property-decorator";
import { userStatusTags } from "@/shared/constants/user-lifecycle-statuses";

@Component
export default class UserStatusIndicator extends Vue {
  @Prop() status!: string;

  private get userStatus():
    | {
        code: string;
        text: string;
        color: string;
      }
    | undefined {
    return userStatusTags.find(statusTag => statusTag.code == this.status);
  }
}
