export const STAGED = "STAGED";
export const PROVISIONED = "PROVISIONED";
export const ACTIVE = "ACTIVE";
export const RECOVERY = "RECOVERY";
export const PASSWORD_EXPIRED = "VS Manager";
export const LOCKED_OUT = "LOCKED_OUT";
export const SUSPENDED = "SUSPENDED";
export const DEPROVISIONED = "DEPROVISIONED";

export const userStatusTags: {
  code: string;
  text: string;
  color: string;
}[] = [
  {
    code: STAGED,
    text: "Staged",
    color: "rgb(221, 179, 44)"
  },
  {
    code: PROVISIONED,
    text: "Pending User Action",
    color: "rgb(221, 179, 44)"
  },
  {
    code: ACTIVE,
    text: "Active",
    color: "#3cc13b"
  },
  {
    code: RECOVERY,
    text: "Password Reset",
    color: "rgb(221, 179, 44)"
  },
  {
    code: PASSWORD_EXPIRED,
    text: "Password Expired",
    color: "#fb2424"
  },
  {
    code: LOCKED_OUT,
    text: "Locked Out",
    color: "#fb2424"
  },
  {
    code: SUSPENDED,
    text: "Suspended",
    color: "#fb2424"
  },
  {
    code: DEPROVISIONED,
    text: "Deactivated",
    color: "#fb2424"
  }
];
