export class RavenUserManagementAddRequest {
  public EmailAddress: string;
  public FirstName: string;
  public LastName: string;
  public Roles: string[];
  public DocumentGroups: string[];

  constructor(
    email: string,
    firstName: string,
    lastName: string,
    roles: string[],
    documentGroups: string[]
  ) {
    this.EmailAddress = email;
    this.FirstName = firstName;
    this.LastName = lastName;
    this.Roles = roles;
    this.DocumentGroups = documentGroups;
  }
}
